@tailwind base;
@tailwind components;
@tailwind utilities;

/* General classes */

@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@200;300;400;500;600;700;800&family=IBM+Plex+Sans+Thai+Looped:wght@100&family=Lato:wght@100;300;400;700;900&family=Merriweather:ital,wght@0,900;1,700;1,900&display=swap'); 

.redBorder {
  border: 1px solid red;
}

.centerFlex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* Auth classes */
.authRed {
  background: linear-gradient(97.65deg, #f44336 0.33%, #ff0000 93.35%);
}

.inputField {
  border: 1px solid #eaeaea;
  background-color: #ffffff;
  border-radius: 9px;
  outline: none;
}
.inputField:focus {
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
}
/*********/

/* Main layout classes*/

.backGround {
  background: #fefcfc;
  box-shadow: 0px 2px 10px rgba(146, 146, 146, 0.03);
}

.dropDownShadow {
  box-shadow: 0px 2px 10px rgba(146, 146, 146, 0.03);
}

.sidebar {
  transform: translateX(0%);
  transition: transform 0.5s ease-in-out;
}

.no-sidebar {
  transform: translateX(-105%);
  transition: transform 0.5s ease-in-out;
}

/*********/

/** Vertical Scrollbar***/
.styled-scrollbar::-webkit-scrollbar {
  background: #e5e5e5;
  width: 8px;
}

.styled-scrollbar::-webkit-scrollbar-track {
  background: #e5e5e5;
  width: 8px;
}

.styled-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px 4px 4px 4px;
}
/*********/

/** Horizontal Scrollbar***/
.horizontal-scrollbar::-webkit-scrollbar {
  background: #e5e5e5;
  height: 4px;
}

.horizontal-scrollbar::-webkit-scrollbar-track {
  background: #e5e5e5;
  height: 4px;
}

.horizontal-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px 4px 4px 4px;
}
/*********/

/*Overview card*/
.overview-card {
  background: #ffffff;
  border: 0.7px solid #eaeaea;
  box-shadow: 0px 0px 12px rgba(187, 187, 187, 0.1);
}

/* Cards**/
.overview-cards {

}

/* subscription page */
.text-gradient {
  background: #ff413b;
  background: radial-gradient(
    circle farthest-corner at center center,
    #ff413b 0%,
    #0e9cc4 30%,
    #0e9cc4 60%,
    #ff3333 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subscription-gradient {
  background: rgb(16,16,16);
background: linear-gradient(58deg, rgba(16,16,16,1) 0%, rgba(255,255,255,1) 0%, rgba(250,238,238,1) 58%, rgba(247,185,185,1) 100%);
}

.subHover:hover{
  transform: scale(1.05);
  transition: transform 0.5s ease-in;
}


.subShadow{
  box-shadow: -1px 1px 5px 0px rgba(94,16,16,0.75);
  -webkit-box-shadow: -1px 1px 5px 0px rgba(94,16,16,0.75);
  -moz-box-shadow: -1px 1px 5px 0px rgba(94,16,16,0.75);
}


/*************/



/*Employee card*/
.employee-card {
  /* background: #ffffff; */
  border: 0.7px solid #eaeaea;
  box-shadow: 0px 0px 12px rgba(187, 187, 187, 0.1);
}

@media (max-width: 1023px) and (min-width: 640px) {
  .subscription-cards:nth-last-child(1) {
    margin: auto;
    grid-column: span 2 / span 2;
  }
}

